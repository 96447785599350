import { render, staticRenderFns } from "./LiveMusic.vue?vue&type=template&id=70ec5639&scoped=true&"
import script from "./LiveMusic.vue?vue&type=script&lang=js&"
export * from "./LiveMusic.vue?vue&type=script&lang=js&"
import style0 from "./LiveMusic.vue?vue&type=style&index=0&id=70ec5639&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70ec5639",
  null
  
)

export default component.exports