<template>
  <div class="main-wrapper">
    <template>
     <loader v-if="isLoaded"
     object="#3370b7" 
     color1="#ffffff" 
     color2="#17fd3d" 
     size="5"
     speed="2" 
     bg="#ffffff" 
     objectbg="#cfcfcf" 
     opacity="80"
      disableScrolling="false"
       name="spinning"></loader>
    </template>
    <div class="fs-container row m-0">
    </div>
    <div class="row">
      <div class="col-lg-6 ">
        <div class="fs-content pt-2 px-5" style="margin-top:85px;">
          <section class="search">
            <div class="row">
              <div class="col-md-12">
                <!-- Row With Forms -->
                <div class="row with-forms">
                  <!-- Main Search Input -->
                  <div class="col-md-6 col-sm-6 col-6">
                    <div class="form-group">
                      <label for="">ZipCode/City</label>
                      <v-select
                        v-model="zipChange"
                        :options="zipcodes"
                        placeholder="Search ZipCode/City"
                        class="zipcode"
                        @search="fetchZipCode"
                      />
                    </div>
                  </div>
                  <!-- Main Search Input -->
                 <div class="col-md-6 col-sm-6 col-6">
                    <div class="form-group">
                      <label for="">Places</label>
                      <v-select
                        v-model="placeChange"
                        placeholder="Search Place"
                        class="places"
                        :options="places_list"
                        @search="fetchPlace"
                      />
                    </div>
                  </div>
                </div>
              <div class="row">
               
              <div class="col-md-6 col-sm-6 col-6">
                <button 
                  @click="showModel"
                  v-if="$isAuth"
                  type="button" 
                  class="btn btn-danger btn-sm"
                  style="width:100%">Add Place
                </button>
                <button 
                  v-else
                  data-toggle="modal"
                  data-target="#login" 
                  type="button" 
                  class="btn btn-danger btn-sm"
                  style="width:100%">Add Place
                </button>
              </div>
               <div class="col-md-6 col-sm-6 col-6">
                <select name="sort" id="sort" class="form-control form-control-sm" @change="sortPlaces">
                  <option disabled hidden value="" selected >Sort By </option>
                  <option value="a">Alphabetical</option>
                  <option value="s">Distance </option>
                  <option value="likes">Most Liked </option>
                </select>
              </div>
                </div>
                <!-- Row With Forms / End -->
              </div>
            </div>
          </section>
          <!-- Search / End -->
          <section class="listings-container">
            <!-- Sorting / Layout Switcher -->
            <div class="row">
               <div class="col-lg-6 map1" style="height:50vh">
                  <map-banner
                    v-if="coordinates"
                   :key="places"
                    type="live-music"
                    layout="sidebar"
                    :coordinates="coordinates"
                    :events="places"
                      style="height:100%"
                    :redoSearch="redoSearch"
                    :locateMe="myFunction"
                      
                  />
                </div>

            </div>
         
            <div class="row fs-switcher results-div">
              <div class="col-md-6 col-sm-6 col-6 ">
                <p
                  v-if="total_pages"
                  class="showing-results"
                >
                  <span  style="color:#337ab7" class="font-weight-bold"> {{ total_pages }}</span> Results Found
                </p>
                <p
                  v-else
                  class="showing-results text-danger font-weight-bold"
                >
                  No Record Found
                </p>
              </div>
              <!-- <div class="col-md-3 col-sm-3 col-3 text-right"></div> -->
              <!-- <div class="col-md-3  col-sm-3 col-3 mb-3" >
                <small class="font-weight-bold">Select Page</small>
                <select @change="pageSelect" name="" id="" class="form-control form-control-sm" v-model="page">
                  <option value="" hidden disabled selected>Select Page </option>
                  <option v-for="page_number in total_pages_number" :key="page_number" :value="page_number" >
                    {{page_number}}
                  </option>
                </select>
              </div> -->
             
            </div>
            <!-- Listings -->
            <div class="row fs-listings" style="min-height:55vh">
              <!-- Listing Item -->
              <div
                v-for="(list,index) in places"
                :key="index"
                class="col-lg-6 col-md-6 grid-layout-list mb-4"
              >
                <div class="list-cap">
                  <div class="list-cap-list mb-4">
                    <!-- <router-link :to="`/live-music/${list.slug}`">	 -->
                     <a :href="list.specials" target="_blank">
                      <div
                        class="img-list"
                        style="width:100%;height:200px;"
                      >
                        <img
                          v-if="list.images[0]"
                          :src="	baseUrl + list.images[list.images.length - 1].image"
                          alt=""
                          class="img-fluid"
                          style="width:100%; height: 100%;object-fit: cover;border-radius:10px"
                        >
                        <img
                          v-else
                          src="/static/images/hh_place.jpg"
                          alt=""
                          class="img-fluid"
                          style="width:100%; height: 100%;object-fit: cover;"
                        >
                      </div>
                     </a>
                    <!-- </router-link> -->
                    <div class="list-cap-content list-cap-content--style">
                      <div class="row">
                        <div class="col-6">
                          <a :href="list.specials" target="_blank"> <span class="badge badge-pill badge-primary text-uppercase mr-2">Calendar </span></a>
                         
                        </div>
                        <div class="col-6 text-right">
                          <span
                            v-if="$isAuth"
                            :id="list.id"
                            style="cursor:pointer;"
                            title="Like"
                            class="round-pill like-button bg-primary"
                            @click="like(list.id)"
                          ><i class="fa fa-thumbs-up" /> {{ list.total_likes }}</span>
                          <span
                            v-else
                            :id="list.id"
                            data-toggle="modal"
                        
                            data-target="#login"
                            style="cursor:pointer;"
                            title="Like"
                            class="round-pill like-button bg-primary"
                            ><i class="fa fa-thumbs-up" /> {{ list.total_likes }}
                          </span>

                        </div>
                      </div>
                      
                      <router-link :to="`/live-music/${list.slug}`">
                        <h5 class="mt-2 place-name" :title="list.name">
                          {{ list.name }}
                        </h5>
                      </router-link>
                      <div
                        class="address-bar "
                        style="color:#525f7f;"
                      >
                        <p>{{ list.address }}, {{ list.city }}, {{ list.state }} {{ list.zipcode }}</p>
                        <i class="fa fa-map-marker" aria-hidden="true" style="color:#e4486d;"></i>
                        <b style="color:#0f7acf">
                          {{ parseFloat(list.distance).toFixed(2) }} Miles
                        </b>
                      </div>
                      <!-- <span
                        v-for="(day,index) in list.days"
                        :key="index"
                      >
                        <span class="badge badge-pill badge-primary text-uppercase mr-2">{{ day }}</span>
                      </span> -->
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
            <div class="row fs-listings">
              <div class="col-md-12">
                <!-- Pagination -->
                <div class="clearfix" />
                <pagination
                  v-if="(Object.keys(places).length > 0)"
                  v-model="page"
                  :records="Math.ceil(total_pages)"
                  :per-page="10"
                  @paginate="callback"
                />
                <div class="clearfix" />
                <!-- Pagination / End -->
              </div>
            </div>
            <!-- Pagination Container / End -->
          </section>
        </div>
      </div>
       <div class="col-lg-6 map2" style="height:75vh;margin-top:50px">
         <map-banner
         name="Calendar"
          v-if="coordinates"
          :key="places"
          type="live-music"
          layout="sidebar"
          :coordinates="coordinates"
          :events="places"
             style="height:100%"
          :redoSearch="redoSearch"
          :locateMe="myFunction"
            
        />
      </div>
    </div>
    <!-- //login modal -->
    <div
      id="login"
      class="modal fade show"
      tabindex="-1"
      role="dialog"
      aria-labelledby="HHModalLabel"
      style="margin-top:90px;"
    >
      <div
        class="modal-dialog "
        role="document"
      >
        <div class="modal-content">
          <div
            class="modal-header"
            style="background-color:currentColor;padding: 6px 6px 6px 18px !important;"
          >     
            <h5 style="color:white;">
              Login
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span
                style="color:white;"
                aria-hidden="true"
              >&times;</span>
            </button>
          </div> 
          <loginModal />
        </div>
      </div>
    </div>
    <!-- //Add Place modal -->
    <div
      id="add_place"
      class="modal fade show"
      tabindex="-1"
      role="dialog"
      aria-labelledby="HHModalLabel"
      style="margin-top:90px;"
    >
      <div
        class="modal-dialog "
        role="document"
      >
        <div class="modal-content">
          <div
            class="modal-header"
            style="background-color:currentColor;padding: 6px 6px 6px 18px !important;"
          >     
            <h5 style="color:white;">
              Add Place
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span
                style="color:white;"
                aria-hidden="true"
              >&times;</span>
            </button>
          </div> 
          <AddPlace type="lm" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import MapBanner from 'Components/globalFrontendComponents/MapBanner';
// import Pagination from 'Components/globalFrontendComponents/Pagination';
import Pagination from 'vue-pagination-2';
import vSelect from 'vue-select';
// import axios from 'axios';
import {axios} from '../services/http-common';
import 'vue-select/dist/vue-select.css';
import MyList from 'Data/listing.json';
import loginModal from '../components/LoginModal.vue';
import AddPlace from '../components/AddPlace.vue';
export default {
  created () {
    document.title = "Live Music - Thriggle";
  },
	components: {
		MapBanner: MapBanner,
      vSelect:vSelect,
      Pagination:Pagination,
      loginModal,
      AddPlace
	},
	data(){
		return{
        isLoaded :true,
         baseUrl:process.env.VUE_APP_STORAGE_URL,
         data: MyList.data,
         places: '',
         filterBy:'s',
          places_list:[],
          lat:'',
          lon:'',
         coordinates: '',
         zipcodes:[{label: "Houston TX 77007"}],
         spinner:true,
         zipChange: localStorage.getItem('hh_zip') ? localStorage.getItem('hh_zip') : 'Houston-TX Area',
         placeChange:'',
         total_pages: '',
           total_pages_number:'',
         page:localStorage.getItem('lm_page') ? parseFloat(localStorage.getItem('lm_page')) : 1,
         area: localStorage.getItem('hh_zip') ? localStorage.getItem('hh_zip') : 'Houston-TX',
		}
	},
  
   watch: {
      zipChange: function(val) {
         if (val) {
            this.page = 1;
            let area = val.label;
            this.area = area;
            if(area){
              localStorage.setItem('hh_zip',area);
            axios
            .get(`${process.env.VUE_APP_API_URL}live-music?orderBy=DESC&sortBy=total_likes&filter=${this.filterBy}&isWeb=1&area=${area}`)
            .then(response => {
               if (!response.error) {
                 if(response.data.total_pages)
                    this.total_pages = response.data.total_pages;
                  else
                    this.total_pages = 0;
                  
                  this.places = response.data.data;
                  this.total_pages = response.data.total_pages;
                  this.coordinates = response.data.coordinates;
                  this.total_pages_number = Math.ceil(this.total_pages/10);
               }
            });
            }
            else
              localStorage.setItem('hh_zip',val);
         }
      },
      placeChange: function(val) {
         let slug = val.slug;
         if (val) {
           this.$router.push({ path: '/live-music/'+slug})
         }
      }
  },
  
   async mounted() {
    
       $(this.$refs.vuemodal).on("hidden.bs.modal", this.doSomethingOnHidden);

      var check = $('#navbar_global').hasClass('show');
      if(check)
        $('#navbar_global').removeClass('show');
      let res = await this.getPlaces();
      this.places = res.data;
      // this.total_pages = res.total_pages;
      this.coordinates = res.coordinates;
       if(res.total_pages)
        this.total_pages = res.total_pages;
      else
        this.total_pages = 0;
      this.total_pages_number = Math.ceil( this.total_pages/10);
      this.isLoaded = false;
    
   },
  updated() {
    $(".vs__dropdown-toggle").css('cursor','pointer');
  },
   methods:{
         myFunction() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          this.showPosition,
          this.errorCallback
        );
      } else {
        this.error = "Geolocation is not supported.";
        this.locateMe();
      }
    },
    showPosition(position) {
      this.lat = position.coords.latitude;
      this.lon = position.coords.longitude;
      this.getLoc();
      if (
        typeof position.coords.latitude === "undefined" ||
        typeof position.coords.longitude === "undefined"
      ) {
        this.locateMe();
      }
    },
    getLoc: function() {
      return axios
            .post(`${process.env.VUE_APP_API_URL}my-location`,{lat:this.lat, lon:this.lon})
            .then(res => {
              //  this.isLoaded = false;
                localStorage.setItem('lat',res.data.latitude);
                localStorage.setItem('lng', res.data.longitude);
               this.redoSearch();
            });
    },
      pageSelect(e){
        this.page = e.target.value;
        this.callback();
      },
     showModel(){
      $('#add_place').modal('toggle');
      $('#add_place').find('form').trigger('reset');
      $('#blah').attr('src', '/static/images/placeholder-min.jpg');
     },
      async sortPlaces(e){
      this.filterBy = e.target.value;
      this.page = 1;
      let res = await this.getPlaces();
      this.places = res.data;
      this.total_pages = res.total_pages;
      this.coordinates = res.coordinates;
     },
         redoSearch(){
            this.isLoaded = true;
       let lat = localStorage.getItem('lat');
       let lng = localStorage.getItem('lng');

        let page = parseFloat(this.page);
         if(this.page > 1)
            page = ( (parseFloat(this.page) -1) * 10);
         else{
            page = 0;
            }
         return axios
            .get( `${process.env.VUE_APP_API_URL}live-music?orderBy=DESC&sortBy=total_likes&lat=${lat}&lng=${lng}&filter=${this.filterBy}&isWeb=1&offset=${page}&type=redo_search`)
            .then(res => {
                this.places = res.data.data;
                this.total_pages = res.data.total_pages;
                this.coordinates = res.data.coordinates;
                this.zipChange =  res.data.area.city +','+ res.data.area.state;
                  this.total_pages_number = Math.ceil(res.data.total_pages/10);
                   this.isLoaded = false;
         });
    },
     locateMe(){
      this.isLoaded = true;
      return  axios
            .get('https://api.ipgeolocation.io/ipgeo?apiKey=fab1a7a1c31a41ee87451a0f63201f73')
            .then((response)  => {
              localStorage.setItem('lat',response.data.latitude);
              localStorage.setItem('lng', response.data.longitude);
              this.redoSearch();
              this.isLoaded = false;
            })
            .catch(function() {});
     },
  
      like(id){
         let data = {};
         data.likeable_type = 'liveMusic';
         data.likeable_id = id;
         
         return axios
            .post( `${process.env.VUE_APP_API_URL}likes`,data)
            .then(response => {
            if(!response.error) {
               $('#'+id).html('<i class="fa fa-thumbs-up"></i> ' + response.data.data.total_likes);
            }
         });
      },
      placeListChange(){
         return axios
            .get(`${process.env.VUE_APP_API_URL}live-music?orderBy=DESC&sortBy=total_likes&filter=${this.filterBy}&isWeb=1&area=Houston-TX`)
            .then(response => {
            if (!response.error) {
               return response.data;
            }
         });
      },
      getPlaces(){
         let page = parseFloat(this.page);
         if(this.page > 1)
            page = ( (parseFloat(this.page) -1) * 10);
         else{
            page = 0;
            }
         return axios
            .get( `${process.env.VUE_APP_API_URL}live-music?orderBy=DESC&sortBy=total_likes&isWeb=1&area=${this.area}&filter=${this.filterBy}&offset=${page}`)
            .then(response => {
            if (!response.error) {
               return response.data;
            }
         });
      },
      fetchZipCode (search, loading) {
         if(search){
            return axios
            .get(`${process.env.VUE_APP_API_URL}filter-location/`+search+'/hh')
            .then(response => {
               if (!response.error) {
                  this.zipcodes = response.data.data;
                
               }
            });
         }
      },
       fetchPlace(search, loading) {
         if(search){
            return axios
            .get(`${process.env.VUE_APP_API_URL}filter-lm-place/`+search)
            .then(response => {
               if (!response.error) {
                  this.places_list = response.data.data;
               }
            });
         }
      },
      callback: function(data) {
        this.isLoaded = true;
         let day = this.placeChange.toLowerCase().replace(/\s/g, '_');
         localStorage.setItem('lm_page',this.page);
         let page = parseFloat(this.page);
         if(this.page > 1)
            page = ( (parseFloat(this.page) -1) * 10);
         else
            page = 0;
         axios.get(`${process.env.VUE_APP_API_URL}live-music?orderBy=DESC&sortBy=total_likes&filter=${day}&filter=${this.filterBy}&isWeb=1&area=`+this.area+'&offset='+page)
         .then(response => {
            if (!response.error) {
               this.places = response.data.data;
               this.coordinates = response.data.coordinates;
               
                window.scrollTo(0, 0);
            }
             this.isLoaded = false;
         });
      },
   },
};
</script>
<style scoped>
.place-name{
    width: 100%;
    overflow: hidden;
    height: 1.4em;
    line-height: 1.4em;
}

@media only screen and (max-width: 990px) {
  .map2{
    display:none !important;
  }
  .map1{
    display:block !important;
  }
  .map-button{
    margin-bottom: 1024px !important;
  }
  .results-div{
    margin-top:70px;
  }
}

@media only screen and (min-width: 990px) {
  .map2{
    display:block !important;
  }
  .map-button{
    margin-bottom: 1042px !important;
  }
  .map1{
    display:none !important;
  }
}
.like-button{
    font-size: 13px;
    cursor: pointer;
    background-color: #5e72e4;
    color: #ffffff;
    padding: 12px 10px 12px 10px;
    border-radius: 50%;
}

</style>